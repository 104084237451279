import {BreakDownServiceCalendarType} from "@app/core";

export class CalendarNote {
  id: string;
  calendarNoteGroupId?: string;
  calendarNoteTypeId: string;
  startDate: Date;
  endDate: Date;
  title?: string;
  comment?: string;
  mechanicId: string;
  mechanicName: string;
  breakDownServiceType?: BreakDownServiceCalendarType;
  reservationItemId?: string;
  reservationParentWorkOrderId?: string;
  groupStartDate?: Date;
  groupEndDate?: Date;
  groupSkipWeekendDays?: boolean;
}

