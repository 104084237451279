export class FiltersSafetyIncidentsTable {
  query?: string;

  getTrimmedFilters(): FiltersSafetyIncidentsTable {
    const trimmedFilters: FiltersSafetyIncidentsTable = Object.assign(new FiltersSafetyIncidentsTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
