import {CentsToEurosPipe} from '@app/core/pipes/cents-to-euros.pipe';
import {EurosToCentsPipe} from '@app/core/pipes/euros-to-cents.pipe';

export enum WorkOrderPeriodTypeEnum {
  WORK,
  TRAVEL,
  BREAK
}

export class WorkOrderPeriod {
  _deletePending? = false;
  _isFirstOfNewDay? = false;

  id: string;
  beginDate: Date;
  endDate: Date;
  beginDateForPriceCalculation: Date;
  endDateForPriceCalculation: Date;
  comment: string;
  workOrderPeriodType: WorkOrderPeriodTypeEnum;
  kilometers: number;
  noKilometersDriven: boolean;
  fromStreet: string;
  fromCity: string;
  toStreet: string;
  toCity: string;
  isOutsmartData: boolean;
  hourType: string;
  hourStatusEnum: number;
  priceCalculationHoursOnly: boolean
  copyNormalHoursToPriceHoursOnChange: boolean
  parentWorkOrderId: string;

  _salesPriceCalculatedEuros?: number;
  salesPriceCalculatedCents: number;
  _costPriceCalculatedEuros: number;
  costPriceCalculatedCents: number;
  doNotInvoiceHoursAndKilometers: boolean;

  mechanicName: string;
  mechanicFunctionEnum?: number;
  workOrderStatusId: string;
  workOrderComment: string;
  workOrderProjectItemName: string;

  groupId?: string;

  convertCentsToEuros?() {
    this._salesPriceCalculatedEuros = CentsToEurosPipe.getEurosFromCents(this.salesPriceCalculatedCents);
    this._costPriceCalculatedEuros = CentsToEurosPipe.getEurosFromCents(this.costPriceCalculatedCents);
  }

  convertEurosToCents?(deleteEuroVars = true) {
    this.salesPriceCalculatedCents = EurosToCentsPipe.getCentsFromEuros(this._salesPriceCalculatedEuros);
    this.costPriceCalculatedCents = EurosToCentsPipe.getCentsFromEuros(this._costPriceCalculatedEuros);

    if (deleteEuroVars) {
      delete this._salesPriceCalculatedEuros;
      delete this._costPriceCalculatedEuros;
    }
  }
}
