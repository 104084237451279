export enum RequestTypeEnum {
  Probleem,
  Verzoek
}

export enum RequestStatusEnum {
  Nieuw,
  'In Ontwikkeling',
  'Te Testen',
  'In productie'
}

export class DevRequest {
  id?: string;
  createdDate?: Date; // Wordt door back-end ingevuld
  createdByUserName?: string; // Wordt door back-end ingevuld
  type: RequestTypeEnum;
  description: string;
  prio: 1 | 2 | 3;
  requestedFor: string; // Bijv. 'afdeling planning' of 'monteurs' of 'externe'.
  scheduledFor?: string | null; // Planningsindicatie
  status: RequestStatusEnum;
}

export const RequestTypeEnumArray: { label: string, value: number }[] = Object
  .keys(RequestTypeEnum).filter((key) => isNaN(Number(key)))
  .map(label => {
    return {label, value: Number(RequestTypeEnum[label])}
  });

export const RequestStatusEnumArray: { label: string, value: number }[] = Object
  .keys(RequestStatusEnum).filter((key) => isNaN(Number(key)))
  .map(label => {
    return {label, value: Number(RequestStatusEnum[label])}
  });