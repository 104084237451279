import {ExperiTableFilters} from "@app/shared/components/experi-table/experi-table-filters.model";

export class FiltersLinkLocationsTable extends ExperiTableFilters {
  query?: string;
  isLinked?: boolean;

  constructor() {
    super();
  }

  getTrimmedFilters(): FiltersLinkLocationsTable {
    const trimmedFilters: FiltersLinkLocationsTable = Object.assign(new FiltersLinkLocationsTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }
    if (this.isLinked || this.isLinked === false) {
      trimmedFilters['isLinked'] = this.isLinked;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
