export class FiltersWorkOrdersTable {
  query?: string;
  startDate?: Date;
  endDate?: Date;
  locationId?: string;
  workOrderStatusIds?: string[];
  workTypeIds?: string[];
  mechanicIds?: string[];
  isArchived?: boolean;
  projectQuery?: string;
  startWorkDateFromDate?: Date;

  getTrimmedFilters(): FiltersWorkOrdersTable {
    const trimmedFilters: FiltersWorkOrdersTable = Object.assign(new FiltersWorkOrdersTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (this.startDate) {
      trimmedFilters['startDate'] = this.startDate;
    }
    if (this.endDate) {
      trimmedFilters['endDate'] = this.endDate;
    }
    if (this.locationId) {
      trimmedFilters['locationId'] = this.locationId;
    }

    if (this.workOrderStatusIds && this.workOrderStatusIds.length > 0) {
      trimmedFilters['workOrderStatusIds'] = this.workOrderStatusIds;
    }

    if (this.workTypeIds && this.workTypeIds.length > 0) {
      trimmedFilters['workTypeIds'] = this.workTypeIds;
    }

    if (this.mechanicIds && this.mechanicIds.length > 0) {
      trimmedFilters['mechanicIds'] = this.mechanicIds;
    }

    if (this.isArchived || this.isArchived === false) {
      trimmedFilters['isArchived'] = this.isArchived;
    }

    if (this.projectQuery && this.projectQuery !== '') {
      trimmedFilters['projectQuery'] = this.projectQuery;
    }
    if (this.startWorkDateFromDate) {
      trimmedFilters['startWorkDateFromDate'] = this.startWorkDateFromDate;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
