import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, Optional, SkipSelf} from '@angular/core';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import {throwIfAlreadyLoaded} from './guards/module-import.guard';
import {DatePipe, DecimalPipe, JsonPipe, registerLocaleData} from '@angular/common';

import {ApiInterceptor} from './interceptors/api-interceptor.service';

// Guards
import {CanSearchGuard} from './guards/can-search.guard';
import {CanViewUsersGuard} from './guards/can-view-users.guard';
import {NoUserGuard} from "./guards/no-user.guard";
import {UserGuard} from './guards/user.guard';
import {CanManageTicketsAndWorkOrdersGuard} from "./guards/can-manage-tickets-and-work-orders.guard";
import {CanViewSensorsGuard} from "./guards/can-view-sensors.guard";
import {CanViewLocationDetailsGuard} from "./guards/can-view-location-details.guard";
import {CanViewCockpitGuard} from "./guards/can-view-cockpit.guard";
import {CanViewTicketsAndWorkOrdersGuard} from "./guards/can-view-tickets-and-work-orders.guard";

import {WINDOW_PROVIDERS} from "@app/core/services/bing-api-window.service";
import {CanManageTemplatesGuard} from "@app/core/guards/can-manage-templates.guard";
import {CanManageCustomersGuard} from "@app/core/guards/can-manage-customers.guard";
import {LightenOrDarkenColorPipe} from '@app/core/pipes/lighten-or-darken-color.pipe';
import {
  CanViewBreakDownServiceCalendarsGuard
} from '@app/core/guards/can-view-break-down-service-calendars-guard';
import {CanViewMechanicsCalendarsGuard} from '@app/core/guards/can-view-mechanics-calendars.guard';
import {CanViewOccupancyRateGuard} from '@app/core/guards/can-view-occupancy-rate.guard';
import {CanViewProjectsGuard} from '@app/core/guards/can-view-projects.guard';
import {CanViewSafetyModuleGuard} from '@app/core/guards/can-view-safety-module.guard';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_PAGINATOR_DEFAULT_OPTIONS} from '@angular/material/paginator';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {CanViewHourReportsGuard} from '@app/core/guards/can-view-hour-reports.guard';
import {CanManageMaterialPresetsGuard} from '@app/core/guards/can-manage-material-presets.guard';
import {CanManageInstallationPartsGuard} from '@app/core/guards/can-manage-installation-parts.guard';
import {CanManageNotifiersGuard} from '@app/core/guards/can-manage-notifiers.guard';
import {CanManageReservationItemsGuard} from '@app/core/guards/can-manage-reservation-items.guard';
import {CanViewAndManageReservationsGuard} from '@app/core/guards/can-view-and-manage-reservations.guard';
import {CanViewLocationsGuard} from '@app/core/guards/can-view-locations.guard';
import {CanViewDownloadsPageGuard} from '@app/core/guards/can-view-downloads-page.guard';
import {CanManageInspectionPartTypesGuard} from '@app/core/guards/can-manage-inspection-part-types.guard';
import {CanViewLogsGuard} from '@app/core/guards/can-view-logs.guard';
import {CanViewRequestsGuard} from '@app/core/guards/can-view-requests.guard';
import {CanViewLinkLocationsGuard} from '@app/core/guards/can-view-link-locations.guard';

@NgModule({
  declarations: [
    LightenOrDarkenColorPipe,
  ],
  imports: [
    HttpClientModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    // Guards
    CanManageTicketsAndWorkOrdersGuard,
    CanSearchGuard,
    CanViewUsersGuard,
    CanViewLocationDetailsGuard,
    CanViewSensorsGuard,
    CanViewCockpitGuard,
    CanViewTicketsAndWorkOrdersGuard,
    CanViewMechanicsCalendarsGuard,
    NoUserGuard,
    UserGuard,
    CanManageTemplatesGuard,
    CanManageCustomersGuard,
    CanViewBreakDownServiceCalendarsGuard,
    CanViewOccupancyRateGuard,
    CanViewProjectsGuard,
    CanViewSafetyModuleGuard,
    CanViewHourReportsGuard,
    CanManageMaterialPresetsGuard,
    CanManageInstallationPartsGuard,
    CanManageNotifiersGuard,
    CanManageReservationItemsGuard,
    CanViewAndManageReservationsGuard,
    CanViewLocationsGuard,
    CanViewDownloadsPageGuard,
    CanManageInspectionPartTypesGuard,
    CanViewLogsGuard,
    CanViewRequestsGuard,
    CanViewLinkLocationsGuard,

    // Pipes
    DecimalPipe,
    JsonPipe,
    DatePipe,
    LightenOrDarkenColorPipe,

    // Defaults
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {
      provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'
    },
    {
      provide: LOCALE_ID, useValue: 'nl-NL'
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {subscriptSizing: 'dynamic'}
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {formFieldAppearance: 'fill'}
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: null,
        maxHeight: null,
        panelClass: 'app-dialog',
      }
    }
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    registerLocaleData(localeNl);
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

