import {Company} from "@app/core/models/company.model";
import {CanViewAndSendPDFuserRoles, OldUserRoles, TicketAddressee} from "@app/core";
import {CentsToEurosPipe} from "@app/core/pipes/cents-to-euros.pipe";
import {EurosToCentsPipe} from "@app/core/pipes/euros-to-cents.pipe";

export type WorkingDay = {
  day: string;
  worksOnDay: boolean;
  start?: string;
  end?: string;
  comment?: string;
};

/*
*
* Reminder!
* When updating a can..() in this file file, also change /user-dialog-roles-info.component.ts
*
* */

export class User {
  id: string;
  isActive: boolean;
  apiToken: string;
  name: string;
  email: string;
  phoneNumber: string;
  companies: Company[];
  companiesCount: number;
  locations: TicketAddressee[];
  locationsCount: number;
  apiTokenExpireDate: Date;
  apiTokenLastUsedDate: Date;
  lastLoginDate: Date;
  secondToLastLoginDate: Date;
  role: OldUserRoles;
  defaultFunction?: number;
  departmentId?: string;
  departmentSort?: number
  canDownloadSubmittedWorkOrderPDF: boolean;
  canEnterWorkOrderPeriodAsBreakType: boolean;
  canBeAssignedToWorkOrder: boolean;
  canMangeSafetyIncidentStatus: boolean;
  canChangeUserRolesInFrontEnd: boolean;
  street: string;
  city: string;
  weekDays?: WorkingDay[];
  mechanicCanViewWorkOrdersPastNextWeekMax: boolean;

  exactProjectManagerId: string;
  exactProjectManagerName: string;
  exactEmployeeId: string;
  exactEmployeeName: string;

  hasCosts: boolean;
  _costPerHourEuros: number;
  costPerHourCents: number;
  _costPerKilometerEuros: number;
  costPerKilometerCents: number;
  forcePointerModeForAllDateTimeInputs: boolean;

  _rights: {
    canViewAndSendPDFs: boolean,
    canViewOnDutyBreakDownService: boolean,
    canManageOnDutyBreakDownService: boolean,
    canViewUsers: boolean,
    canManageUsers: boolean,
    canViewCockpit: boolean,
    canChooseCockpitMode: boolean,
    canViewLocations: boolean,
    canViewLocationDetails: boolean,
    canManageLocations: boolean,
    canManageOwnLocationEvents: boolean,
    canManageAllLocationEvents: boolean,
    canViewSensors: boolean,
    canViewMaterialPrices: boolean,
    hasToEnterMaterialPriceBeforeSave: boolean,
    canViewManageMaterialDirectlyOnTicket: boolean,
    canManageMaterialPresets: boolean,
    canManageEmails: boolean,
    canDeleteAndEditExternalFiles: boolean,
    canManageTicketsAndWorkOrders: boolean,
    canCloseTicketsAndWorkOrders: boolean,
    canViewTicketsAndWorkOrders: boolean,
    canFillInAllNonClosedWorkOrderMechanicForms: boolean,
    canViewErroneousTickets: boolean,
    canFilterOnDeletedTicketStates: boolean,
    canOpenOutSmartLinks: boolean,
    canViewAndManagePriceCalculationHours: boolean,
    canEditWorkOrderCommentMechanicWhenWorkOrderIsClosed: boolean,
    canSearch: boolean,
    canViewMechanicsCalendars: boolean,
    canManageMechanicsCalendars: boolean,
    canViewProjects: boolean,
    canManageProjectInWorkOrders: boolean,
    canFillInOwnNonClosedWorkOrderMechanicForms: boolean,
    canViewAndManageTemplates: boolean,
    canViewAndManageCustomers: boolean,
    canManageRelationTypes: boolean,
    canViewOccupancyRate: boolean,
    canViewSafetyModule: boolean,
    canCreateSafetyIncident: boolean,
    canEditAndDeleteAnyUserOpenSafetyIncident: boolean,
    canEditAndDeleteOwnOpenSafetyIncident: boolean,
    canManageDocuments: boolean,
    canViewHourReports: boolean,
    canManageInstallationParts: boolean,
    canManageNotifiers: boolean,
    canManageReservationItems: boolean,
    canViewAndManageReservations: boolean,
    canViewExactTokenExpireDate: boolean,
    canViewDownloadsPage: boolean,
    canManageInspectionPartTypes: boolean,
    canViewInspectionParts: boolean,
    canManageInspectionForms: boolean,
    canViewInspectionResults: boolean,
    canViewLogs: boolean,
    canViewRequests: boolean,
    canViewLinkLocations: boolean,
    canViewImportedTickets: boolean,
  }

  setRights() {
    this._rights = {
      canViewAndSendPDFs: this.canViewAndSendPDFs(),
      canViewOnDutyBreakDownService: this.canViewOnDutyBreakDownService(),
      canManageOnDutyBreakDownService: this.canManageOnDutyBreakDownService(),
      canViewUsers: this.canViewUsers(),
      canManageUsers: this.canManageUsers(),
      canViewCockpit: this.canViewCockpit(),
      canChooseCockpitMode: this.canChooseCockpitMode(),
      canViewLocations: this.canViewLocations(),
      canViewLocationDetails: this.canViewLocationDetails(),
      canManageLocations: this.canManageLocations(),
      canManageOwnLocationEvents: this.canManageOwnLocationEvents(),
      canManageAllLocationEvents: this.canManageAllLocationEvents(),
      canViewSensors: this.canViewSensors(),
      canViewMaterialPrices: this.canViewMaterialPrices(),
      hasToEnterMaterialPriceBeforeSave: this.hasToEnterMaterialPriceBeforeSave(),
      canViewManageMaterialDirectlyOnTicket: this.canViewManageMaterialDirectlyOnTicket(),
      canManageMaterialPresets: this.canManageMaterialPresets(),
      canManageEmails: this.canManageEmails(),
      canDeleteAndEditExternalFiles: this.canDeleteAndEditExternalFiles(),
      canManageTicketsAndWorkOrders: this.canManageTicketsAndWorkOrders(),
      canCloseTicketsAndWorkOrders: this.canCloseTicketsAndWorkOrders(),
      canViewTicketsAndWorkOrders: this.canViewTicketsAndWorkOrders(),
      canFillInAllNonClosedWorkOrderMechanicForms: this.canFillInAllNonClosedWorkOrderMechanicForms(),
      canViewErroneousTickets: this.canViewErroneousTickets(),
      canFilterOnDeletedTicketStates: this.canFilterOnDeletedTicketStates(),
      canOpenOutSmartLinks: this.canOpenOutSmartLinks(),
      canViewAndManagePriceCalculationHours: this.canViewAndManagePriceCalculationHours(),
      canEditWorkOrderCommentMechanicWhenWorkOrderIsClosed: this.canEditWorkOrderCommentMechanicWhenWorkOrderIsClosed(),
      canFillInOwnNonClosedWorkOrderMechanicForms: this.canFillInOwnNonClosedWorkOrderMechanicForms(),
      canSearch: this.canSearch(),
      canViewMechanicsCalendars: this.canViewMechanicsCalendars(),
      canManageMechanicsCalendars: this.canManageMechanicsCalendars(),
      canViewProjects: this.canViewProjects(),
      canManageProjectInWorkOrders: this.canManageProjectInWorkOrders(),
      canViewAndManageTemplates: this.canViewAndManageTemplates(),
      canViewAndManageCustomers: this.canViewAndManageCustomers(),
      canManageRelationTypes: this.canManageRelationTypes(),
      canViewOccupancyRate: this.canViewOccupancyRate(),
      canViewSafetyModule: this.canViewSafetyModule(),
      canCreateSafetyIncident: this.canCreateSafetyIncident(),
      canEditAndDeleteAnyUserOpenSafetyIncident: this.canEditAndDeleteAnyUserOpenSafetyIncident(),
      canEditAndDeleteOwnOpenSafetyIncident: this.canEditAndDeleteOwnOpenSafetyIncident(),
      canManageDocuments: this.canManageDocuments(),
      canViewHourReports: this.canViewHourReports(),
      canManageInstallationParts: this.canManageInstallationParts(),
      canManageNotifiers: this.canManageNotifiers(),
      canManageReservationItems: this.canManageReservationItems(),
      canViewAndManageReservations: this.canViewAndManageReservations(),
      canViewExactTokenExpireDate: this.canViewExactTokenExpireDate(),
      canViewDownloadsPage: this.canViewDownloadsPage(),
      canManageInspectionPartTypes: this.canManageInspectionPartTypes(),
      canViewInspectionParts: this.canViewInspectionParts(),
      canManageInspectionForms: this.canManageInspectionForms(),
      canViewInspectionResults: this.canViewInspectionResults(),
      canViewLogs: this.canViewLogs(),
      canViewRequests: this.canViewRequests(),
      canViewLinkLocations: this.canViewLinkLocations(),
      canViewImportedTickets: this.canViewImportedTickets(),
    }
  }

  convertCentsToEuros?() {
    this._costPerHourEuros = CentsToEurosPipe.getEurosFromCents(this.costPerHourCents);
    this._costPerKilometerEuros = CentsToEurosPipe.getEurosFromCents(this.costPerKilometerCents);
  }

  convertEurosToCents?(deleteEuroVars = true) {
    this.costPerHourCents = EurosToCentsPipe.getCentsFromEuros(this._costPerHourEuros);
    this.costPerKilometerCents = EurosToCentsPipe.getCentsFromEuros(this._costPerKilometerEuros);
    if (deleteEuroVars) {
      delete this._costPerHourEuros;
      delete this._costPerKilometerEuros;
    }
  }


  // canViewAndSendPDFs
  private canViewAndSendPDFs(): boolean {
    return CanViewAndSendPDFuserRoles.includes(this.role);
  }

  // OnDutyBreakDownService
  private canViewOnDutyBreakDownService(): boolean {
    if (this.canViewOccupancyRate()) return true;
    return ['mechanic'].includes(this.role);
  }

  private canManageOnDutyBreakDownService(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  // Users
  private canViewUsers(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  private canManageUsers(): boolean {
    return this.role === 'superAdmin';
  }

  // CockPit
  private canViewCockpit(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'external', 'mechanic'].includes(this.role);
  }

  private canChooseCockpitMode(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  // Locations
  private canViewLocations(): boolean {
    return this.role !== 'sensorObserver';
  }

  private canViewLocationDetails(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'external', 'mechanic'].includes(this.role);
  }

  private canManageLocations(): boolean {
    return ['superAdmin', 'admin', 'projectLeader'].includes(this.role);
  }

  private canManageOwnLocationEvents(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'externalEventPlanner'].includes(this.role);
  }

  private canManageAllLocationEvents(): boolean {
    return ['superAdmin', 'admin', 'projectLeader'].includes(this.role);
  }

  // Sensors
  private canViewSensors(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'external', 'sensorObserver', 'mechanic'].includes(this.role);
  }

  // Materials
  private canViewMaterialPrices(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  private hasToEnterMaterialPriceBeforeSave(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  private canViewManageMaterialDirectlyOnTicket(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'external'].includes(this.role);
  }

  private canManageMaterialPresets(): boolean {
    return ['superAdmin', 'admin'].includes(this.role);
  }

  // Emails
  private canManageEmails(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  // ExternalFiles
  private canDeleteAndEditExternalFiles(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  // Tickets and WorkOrders
  private canManageTicketsAndWorkOrders(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  private canCloseTicketsAndWorkOrders(): boolean {
    return ['superAdmin', 'admin'].includes(this.role);
  }

  private canViewTicketsAndWorkOrders(): boolean {
    if (this.canManageTicketsAndWorkOrders()) {
      return true;
    }
    return ['external', 'mechanic'].includes(this.role);
  }

  private canFillInAllNonClosedWorkOrderMechanicForms(): boolean {
    return this.canManageTicketsAndWorkOrders();
  }

  private canFillInOwnNonClosedWorkOrderMechanicForms(): boolean {
    if (this.canFillInAllNonClosedWorkOrderMechanicForms()) return true;
    return ['mechanic'].includes(this.role);
  }

  private canViewErroneousTickets(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  private canFilterOnDeletedTicketStates(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'mechanic'].includes(this.role);
  }

  private canOpenOutSmartLinks(): boolean {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'mechanic'].includes(this.role);
  }

  private canViewAndManagePriceCalculationHours(): boolean {
    return ['superAdmin', 'admin'].includes(this.role);
  }

  private canEditWorkOrderCommentMechanicWhenWorkOrderIsClosed(): boolean {
    return ['superAdmin', 'admin'].includes(this.role);
  }

  // Search
  private canSearch() {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'mechanic'].includes(this.role);
  }

  // Calendar
  private canViewMechanicsCalendars() {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'mechanic'].includes(this.role);
  }

  private canManageMechanicsCalendars() {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  // Projects
  private canViewProjects() {
    return ['superAdmin', 'admin', 'projectLeader'].includes(this.role);
  }

  private canManageProjectInWorkOrders() {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  // Pricing Tables
  private canViewAndManageTemplates() {
    return ['superAdmin', 'admin', 'projectLeader'].includes(this.role);
  }

  // Customers
  private canViewAndManageCustomers() {
    return ['superAdmin', 'admin', 'projectLeader'].includes(this.role);
  }

  // Relations
  private canManageRelationTypes() {
    return ['superAdmin', 'admin', 'projectLeader'].includes(this.role);
  }

  // Occupancy Rate
  private canViewOccupancyRate() {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator'].includes(this.role);
  }

  // Safety
  private canViewSafetyModule() {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'mechanic'].includes(this.role);
  }

  private canCreateSafetyIncident() {
    return ['superAdmin', 'admin', 'projectLeader', 'coordinator', 'mechanic'].includes(this.role);
  }

  private canEditAndDeleteAnyUserOpenSafetyIncident() {
    return ['superAdmin', 'admin', 'projectLeader'].includes(this.role);
  }

  private canEditAndDeleteOwnOpenSafetyIncident() {
    return ['coordinator', 'mechanic'].includes(this.role);
  }

  private canManageDocuments() {
    return ['superAdmin', 'admin', 'projectLeader'].includes(this.role);
  }

  // HourReports
  private canViewHourReports() {
    return ['superAdmin', 'admin', 'coordinator', 'mechanic', 'projectLeader'].includes(this.role);
  }

  // InstallationParts
  private canManageInstallationParts() {
    return ['admin', 'superAdmin'].includes(this.role);
  }

  // Notifiers
  private canManageNotifiers() {
    return ['admin', 'superAdmin'].includes(this.role);
  }

  // Reservations
  private canManageReservationItems() {
    return ['admin', 'superAdmin'].includes(this.role);
  }

  private canViewAndManageReservations() {
    return ['superAdmin', 'admin', 'coordinator', 'mechanic', 'projectLeader'].includes(this.role);
  }

  // ExactToken
  private canViewExactTokenExpireDate() {
    return ['superAdmin', 'admin'].includes(this.role);
  }

  // Downloads
  private canViewDownloadsPage() {
    return ['superAdmin'].includes(this.role);
  }

  // Inspections
  private canManageInspectionPartTypes() {
    return ['superAdmin', 'admin'].includes(this.role);
  }

  private canViewInspectionParts() {
    return ['superAdmin', 'admin', 'coordinator', 'mechanic', 'projectLeader'].includes(this.role);
  }

  private canManageInspectionForms() {
    return ['superAdmin', 'admin', 'coordinator', 'projectLeader'].includes(this.role);
  }

  private canViewInspectionResults() {
    return ['superAdmin', 'admin', 'coordinator', 'mechanic', 'projectLeader'].includes(this.role);
  }

  // Logs
  private canViewLogs() {
    return ['superAdmin', 'admin'].includes(this.role);
  }
  // Requests
  private canViewRequests() {
    return ['superAdmin'].includes(this.role);
  }
  // LinkLocations
  private canViewLinkLocations() {
    return ['superAdmin', 'admin'].includes(this.role);
  }
  private canViewImportedTickets() {
    return ['superAdmin', 'admin', 'coordinator', 'projectLeader'].includes(this.role);
  }
}
