export class FiltersLogsTable {
  query?: string;
  levels?: string[];
  logger?: string;
  from?: Date;
  till?: Date;

  getTrimmedFilters?(): FiltersLogsTable {
    const trimmedFilters: FiltersLogsTable = Object.assign(new FiltersLogsTable(), {});

    if (this.query && this.query !== '') {
      trimmedFilters['query'] = this.query;
    }

    if (this.levels && this.levels.length > 0) {
      trimmedFilters['levels'] = this.levels;
    }

    if (this.logger && this.logger !== '') {
      trimmedFilters['logger'] = this.logger;
    }

    if (this.from) {
      trimmedFilters['from'] = this.from;
    }

    if (this.till) {
      trimmedFilters['till'] = this.till;
    }

    if (Object.keys(trimmedFilters).length > 0) {
      return trimmedFilters;
    } else {
      return null;
    }
  }
}
